import { Injectable } from '@angular/core';
import { PromptService } from '../promtp-service/prompt.service';
import { OrganizationConsumerService } from 'src/app/api/proxy/organization/organization-services';
import { OrganizationCodeModel, OrganizationTokenModel } from 'src/app/api/proxy/organization/organization-models';
import { firstValueFrom, of, timeout } from 'rxjs';
import { SecureStorageService } from '../storage/secure-storage.service';
import { SecureStorageKey } from '../storage/models/secure-storage-key.enum';
import { MPIConsumerService } from 'src/app/api/proxy/mpi/mpi-services';
import { ConsentUpdateModel } from 'src/app/api/proxy/mpi/mpi-models';
import { EventService } from '../events/event.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private NETWORK_TIMEOUT = 15000;
  constructor(protected _PromptService: PromptService,
    private _OrganizationConsumerService: OrganizationConsumerService,
    private _StorageService: SecureStorageService,
    private _MPIConsumerService: MPIConsumerService,
    private _EventService: EventService
  ) {

  }

  public async GetByTokenAsync(organizationToken: string): Promise<OrganizationTokenModel | null> {
    try {
      const response = await firstValueFrom(
        this._OrganizationConsumerService.consumerGetByTokenTokenGet({ token: organizationToken })
          .pipe(timeout(this.NETWORK_TIMEOUT))
      );

      return response;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'GetByTokenAsync');
      return null;
    }
  }

  public async SetDefaultOrganization(defaultOrg: OrganizationCodeModel): Promise<void> {
    await this._StorageService.set(SecureStorageKey.DefualtOrginization, JSON.stringify(defaultOrg));
  }

  public async GetDefaultOrganization(): Promise<OrganizationCodeModel | null> {
    var defaultOrgStr = await this._StorageService.get(SecureStorageKey.DefualtOrginization);
    if (!defaultOrgStr) {
      return null;
    }
    return JSON.parse(defaultOrgStr) as OrganizationCodeModel;
  }

  public async GrantConsentForOrganization(organizationId: string): Promise<void> {
    try {
      var request: ConsentUpdateModel = {
        granted: true
      };
      const response = await firstValueFrom(
        this._MPIConsumerService.consumerOrganizationConsentIdPost({ id: organizationId, body: request })
          .pipe(timeout(this.NETWORK_TIMEOUT))
      );
      return response;
    } catch (error) {
      await this._PromptService.showNetworkConnectionError(error, 'GrantConsentForOrganization');
    }
  }

  public async initService(forced:boolean = false): Promise<void> {

      try {

        const organizationList = await firstValueFrom(
          this._MPIConsumerService.consumerOrganizationsGet()
            .pipe(timeout(this.NETWORK_TIMEOUT))
        );

        var orgs: OrganizationTokenModel[] = [];
        var defaultOrg = await this.GetDefaultOrganization();

        if (organizationList && organizationList.length > 0) {
          for (const a of organizationList) {
            if (a.organizationId) {
              try {
                // Attempt to get the organization details
                const orgDetail = await firstValueFrom(
                  this._OrganizationConsumerService.consumerGetByIdOrganizationIdGet({ organizationId: a.organizationId })
                    .pipe(timeout(this.NETWORK_TIMEOUT))
                );

                if (orgDetail) {
                  orgs.push(orgDetail);
                  if(defaultOrg == null){
                    defaultOrg = {
                      active : orgDetail.active,
                      aiChatEnabled: orgDetail.aiChatEnabled,
                      id: orgDetail.id,
                      alias : orgDetail.alias,
                      name : orgDetail.name,
                      numberOfScans : orgDetail.numberOfScans,
                      scanLimitType : orgDetail.scanLimitType,
                      shortCode: orgDetail.shortCode,
                      vivaScoreConsent: orgDetail.vivaScoreConsent
                    };
                    await this.SetDefaultOrganization(defaultOrg);
                  }
                }
              } catch (orgError) {
                // Type narrowing: check if orgError is an object and has a status property
                if (orgError instanceof Object && 'status' in orgError) {
                  const errorStatus = (orgError as any).status;
                  if (errorStatus === 404 || errorStatus === 400) {
                    console.error(`Organization ${a.organizationId} not found or bad request.`);
                  } else {
                    console.error(`Error fetching details for organization ${a.organizationId}`, orgError);
                  }
                } else {
                  // Handle unknown error types
                  console.error(`Unknown error fetching details for organization ${a.organizationId}`, orgError);
                }
              }
            }
          }

          await this._StorageService.set(SecureStorageKey.OrganizationList, JSON.stringify(orgs));
          this._EventService.organizationConfigLoaded.emit(orgs);
        }

      } catch (error) {
        await this._PromptService.showNetworkConnectionError(error, 'GetOrganizationConfig');

      }





  }

  public async AIChatEnabled(): Promise<boolean> {
    var temp = await this._StorageService.get(SecureStorageKey.OrganizationList);
    var model: OrganizationTokenModel[] | null = null;
    if (temp) {
      try {
        model = JSON.parse(temp);

      } catch {
        model = null;
      }
    }

   // var orgConfig = await this.GetOrganizationConfig();
    if (model) {
      var enabledOrg = model.find(a => a.active && a.aiChatEnabled);
      if (enabledOrg) {
        return true;
      }
    }
    return false;
  }

}
