import {
  Component,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ServicesStartupService } from './services/services-startup/services-startup.service';
import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NavController } from '@ionic/angular';
import { EventService } from './services/events/event.service';
import { SystemLogService } from './services/systemlog-service/systemlog.service';
import { environment } from 'src/environments/environment';
import { register } from 'swiper/element/bundle';
import { StorageService } from './services/storage/storage.service';
import { SecureStorageKey } from './services/storage/models/secure-storage-key.enum';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('viewContainerRef', { read: ViewContainerRef, static: true }) vcr!: ViewContainerRef;
  
  public showMenu = true;
  busyLoading = false;

  constructor(
    private router: Router,
    private zone: NgZone,
    public servicesStartupService: ServicesStartupService,
    public navCtrl: NavController,
    private events: EventService,
    private _systemLogService: SystemLogService,
    private renderer: Renderer2,
    private _storageService: StorageService,
    private _events: EventService
  ) {
    this.events.loadingEvent.subscribe(async (loading: number) => {
      this.busyLoading = loading > 0;
    });

    router.events.forEach(async (event: any) => {     //After Navigation     
      if (event instanceof NavigationEnd) {
        if (event.url) {
          if (event.url.toLowerCase().startsWith("/welcome".toLowerCase())) {
            this.showMenu = false;
          }
          if (event.url.toLowerCase().startsWith("/register".toLowerCase())) {
            this.showMenu = false;
          }
          if (event.url.toLowerCase().startsWith("/face-scan-web-result".toLowerCase())) {
            this.showMenu = false;
          }
          if (event.url.toLowerCase().startsWith("/wearable-connected".toLowerCase())) {
            this.showMenu = false;
          }
          if (event.url.toLowerCase().startsWith("/v2".toLowerCase())) {
            this.showMenu = false;
          }
          else {
            this.showMenu = true;
          }
        }
      }
    });
    this.initializeApp();
  }

  initializeApp() {
    App.removeAllListeners();
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(async () => {
        const slug = event.url.split(".app").pop();
        if (slug) {
          try {
            var path = slug.toLowerCase();
            if (path.includes(environment.deepLinkURLBase!)) {
              path = path.replace(environment.deepLinkURLBase!, '');
              if (path.startsWith('/'))
                path = path.replace(/^./, "");
            }
            //set a one time deeplink url
            //this.events.appDeeplinkEvent.emit(path);
            await this.navigateToDeepLink(path);
           
          } catch (e) {
            console.error(e);
          }
        }
      });
    });

    App.addListener('appStateChange', ({ isActive }) => {
      this._systemLogService.appStateChanged(isActive);
    });

    App.addListener('resume', () => {
      this.zone.run(async () => {
        await this.servicesStartupService.resumeApp();
      });
      this.events.appResumed.emit();

    });

    App.addListener('pause', () => {
      this.events.appPaused.emit();
    });

    
  }


  async ngOnInit(): Promise<void> {

    if (!Capacitor.isNativePlatform()) {
      this.renderer.addClass(document.body, 'web-platform');
    }
    await this.servicesStartupService.initServices();
  }

  async navigateToDeepLink(path: string):Promise<void>{
    var linkPath={
      path: path
    }
    var pathJSON = JSON.stringify(linkPath);
    await this._storageService.set(SecureStorageKey.DeeplinkPath,pathJSON);
    this._events.appDeeplinkEvent.emit(path);
  }

}
